.col-3 {
    margin: auto;
    padding: 0;
    margin-top: 50px;
    background-color: white;
    overflow: hidden;
    border-radius: 10px;
    width: 25vw;
    min-height: 550px;
}
.col-3 #mainImgView {
    width: 100%;
}
.col-3 #subImgView {
    width: 30%;
    display: block;
    margin: auto;
    margin-top: -65%;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.col-3 div {
    padding: 20px;
}