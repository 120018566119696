.rescovtrans  {
    padding: 10px 25px;
    border: 0;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-decoration: none;
    color: rgba(44, 0, 138,1);
    background-color: white;
    font-size: x-large;
    font-weight: bold;
    transition: 0.5s;
}
.rescovtrans:hover{
    padding: 10px 25px;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    text-decoration: none;
    color: rgba(44, 0, 138,1);
    background-color: white;
    font-size: x-large;
    font-weight: bold;
    transition: 0.5s;
  }